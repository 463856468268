import { instance } from "@/app/(dashboard)/(sidebar)/api/api"
import { useSnackbarStore } from "@/store/snackbarStore"

export const logout = async (next?: string) => {
  const closeSnackbar = useSnackbarStore.getState().closeSnackbar
  await instance.post("/logout").catch(() => {
    closeSnackbar()
    if (next) {
      window.location.href = "/login"
    }
  })
}
