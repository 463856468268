import { create } from "zustand"
import * as React from "react"

type SnackbarType = "info" | "success" | "delete" | "danger"

interface SnackbarState {
  isOpen: boolean
  message: React.ReactNode
  type: SnackbarType
  onClose?: () => void
  openSnackbar: (
    message: React.ReactNode,
    type?: SnackbarType,
    options?: OpenSnackbarOptions,
  ) => void
  closeSnackbar: () => void
}

type OpenSnackbarOptions = {
  onClose?: () => void
}

export const useSnackbarStore = create<SnackbarState>()((set, get) => ({
  isOpen: false,
  message: "",
  type: "success",
  onOpen: undefined,
  onClose: undefined,
  openSnackbar: (message, type = "success", options) => {
    const { isOpen, onClose: prevOnClose } = get()
    const { onClose: nextOnClose } = options || {}
    if (isOpen) {
      set({ isOpen: false, message, type })
      prevOnClose?.()
      setTimeout(() => {
        set({ isOpen: true, message, type, onClose: nextOnClose })
      }, 100)
    } else {
      set({ isOpen: true, message, type, onClose: nextOnClose })
    }
  },
  closeSnackbar: () => {
    const { isOpen, onClose } = get()
    if (isOpen) {
      set({ isOpen: false })
      onClose?.()
    }
  },
}))
