import { queryOptions } from "@tanstack/react-query"

import { ApiErrorResponse } from "@/clients/types"
import { profileId } from "@/app/(dashboard)/(sidebar)/api/config"
import { ProfileModel } from "@/features/profiles"

import { toSelectProfileModel } from "./select"
import { GetProfileApi, getProfileApi } from "./apis"

export const profileQueries = {
  _id: ["profiles", profileId],
  profile: () => {
    return queryOptions<GetProfileApi["Response"], ApiErrorResponse, ProfileModel>({
      queryKey: [...profileQueries._id],
      queryFn: getProfileApi,
      select: toSelectProfileModel,
    })
  },
}
