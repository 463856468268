import axios from "axios"
import qs from "qs"

import { logout } from "@/app/(dashboard)/(sidebar)/api/logout/api"
import { useLoadingOverlayStore } from "@/store/loadingOverlayStore"
import { useSnackbarStore } from "@/store/snackbarStore"

const isServer = typeof window === "undefined"

export const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
})

// 배열 형태의 쿼리 파라미터를 전송할 때, 배열 중괄호 [] 반영하지 않도록 설정
instance.defaults.paramsSerializer = function (paramObj) {
  return qs.stringify(paramObj, { arrayFormat: "repeat" })
}

instance.interceptors.request.use(async config => {
  if (
    config.url?.includes("/workspaces/undefined") &&
    /**
     * @todo js에서 window 객체를 바로 사용하지 않도록 수정
     */
    !["/login", "/join"].includes(window.location.pathname)
  ) {
    logout("/login")
  }

  if (isServer) {
    // 서버 사이드에서 쿠키 설정
    const { cookies } = await import("next/headers")
    const existsCookies = cookies()
      .getAll()
      .map(cookie => `${cookie.name}=${cookie.value}`)
      .join(";")

    if (existsCookies) {
      config.headers.cookie = existsCookies
    }
  }
  // 클라이언트 사이드에서는 쿠키 설정을 하지 않습니다.

  return config
})

instance.interceptors.response.use(
  response => response,
  error => {
    useLoadingOverlayStore.getState().stopLoading()

    if (error.response) {
      switch (error.response.status) {
        /**
         * 401에러에는 권한에러도 포함되기 때문에 로그아웃을 하면 안됩니다.
         * @todo js에서 window 객체를 바로 사용하지 않도록 수정
         */
        case 401: {
          if (!["/login", "/join"].includes(window.location.pathname)) {
            logout("/login")
          }
          break
        }
        case 402:
          useSnackbarStore
            .getState()
            .openSnackbar("해당 기능은 차단되었습니다.", "danger")
          break
        case 302:
          window.location.reload()
          break
      }

      const errorMessage =
        error.response.data?.body?.message ||
        error.response.data?.message ||
        "알 수 없는 오류가 발생했습니다."

      if (errorMessage === "[요청중복]") return

      useSnackbarStore.getState().openSnackbar(errorMessage, "danger")
    } else {
      // 네트워크 오류 또는 서버 응답 없음
      const errorMessage = error?.message || "네트워크 오류가 발생했습니다."

      useSnackbarStore.getState().openSnackbar(errorMessage, "danger")
    }

    return Promise.reject(error)
  },
)

export default instance
