import { LocaleType, NumberFormatMap } from "./i18n.types"

export const LOCALES: LocaleType[] = ["ko"]

export const DEFAULT_LOCALE: LocaleType = "ko"

export const CURRENCIES: NumberFormatMap = {
  ko: {
    style: "decimal", //"currency",
    currency: "KRW",
  },
}
