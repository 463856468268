/**
 * 종사업장 번호 숫자 4자리
 * @example 0000
 */
export const formatTaxRegistrationId = (value: string) => {
  if (!value) return value

  const numbers = value.replace(/[^\d]/g, "")
  return numbers.slice(0, 4)
}
