import { map, pipe, toArray } from "@fxts/core"

import { ProfileModel } from "@/features/profiles"
import { formatBusinessRegistrationNumber, formatPhoneNumber } from "@/utils/formats"
import { formatTaxRegistrationId } from "@/utils/format"

import { GetProfileApi } from "./apis"

export const toSelectProfileModel = <T extends GetProfileApi["Response"]>(
  data: T,
): ProfileModel => {
  return {
    ...data,
    workspaces: pipe(
      data.workspaces,
      map(workspaceGroup => ({
        ...workspaceGroup,
        workspace: {
          ...workspaceGroup.workspace,
          businessRegistrationNumber: formatBusinessRegistrationNumber(
            workspaceGroup.workspace.businessRegistrationNumber,
          ),
          staff: {
            ...workspaceGroup.workspace.staff,
            telephone: formatPhoneNumber(
              workspaceGroup.workspace.staff.telephone || "",
            ),
          },
          taxRegistrationId: formatTaxRegistrationId(
            workspaceGroup.workspace.taxRegistrationId || "",
          ),
        },
      })),
      toArray,
    ),
  }
}
