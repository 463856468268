/**
 * 자동정렬하면 언어별 라인수차이로 인해 가독성 및 놓치는 메세지를 인지하기 어렵습니다.
 * 따라서 prettier의 max-len을 무시하기 위해 주석을 추가합니다. (mex-len만 별도로 끄는게 불가능)
 */
/* eslint-disable prettier/prettier */
import { MessageGroup } from "../i18n.types"

const messages: MessageGroup = {
  "error.apis.unexpected": "알 수 없는 오류가 발생했습니다.",
  "error.page.500": "예기치 못한 에러가 발생했습니다.",

  "invitations.modal.title": "초대 대기",
  "invitations.modal.description": "아직 워크스페이스에 참여하지 않은 팀원이에요.",
  "invitations.list.total": "전체 {{count}}건",
  "invitations.list.empty": "초대 대기 중인 팀원이 없습니다.",
  "invitations.status.pending": "초대중",
  "invitations.table.header.status": "상태",
  "invitations.table.header.email": "이메일",
  "invitations.table.header.role": "초대권한",
  "invitations.action.resend": "메일 재전송",
  "invitations.action.cancel": "초대 취소",
  "invitations.cancel.title": "팀원 초대를 취소할까요?",
  "invitations.cancel.description": "취소된 팀원은 기존 이메일로 가입할 수 없으며,\n새로 초대해야 합니다.",
  "invitations.cancel.confirm": "네, 취소할게요.",
  "invitations.snackbar.resent": "팀원 초대 메일을 재전송했습니다.",
  "invitations.snackbar.canceled": "팀원 초대를 취소했습니다.",

  "certification.success.register": "인증서가 등록되었습니다.",
  "certification.success.join": "인증서를 통해 워크스페이스에 참여했습니다.",
  "certification.error.businessNumber": "사업자등록번호와 일치하지 않은 인증서입니다.\n다른 인증서로 등록해 주세요.",
  "certification.error.taxInvoice": "세금계산서 발행이 가능한 인증서로 등록해 주세요.",
  "certification.error.unexpected": "예상하지 못한 오류가 발생했습니다. 지속적으로 문제 발생 시 문의해 주세요.",

  "auth.workspace.certification.needCertification": "인증 필요",
  "auth.workspace.certification.successCertification": "인증 완료",
  "auth.workspace.certification.representative": "대표자명",
  "auth.workspace.certification.businessNumber": "사업자등록번호",
  "auth.workspace.certification.button": "공동인증서 인증",
  "auth.workspace.certification.notice": "공동인증서 인증을 할 수 없는 팀원이라면,\n워크스페이스에서 '초대권한'을 가진 팀원에게 초대를 요청해 주세요.",

  "workspace.certification.badge": "인증필요",
  "workspace.certification.representative": "대표자명",
  "workspace.certification.businessNumber": "사업자등록번호",
  "workspace.certification.button": "공동인증서 인증",
  "workspace.certification.notice": "공동인증서 인증을 할 수 없는 팀원이라면,\n워크스페이스에서 '초대권한'을 가진 팀원에게 초대를 요청해 주세요.",
}

export default messages
