import dayjs from "dayjs"

/**
 * Time을 제외한 Date input을 포메팅하는 함수
 * - ISO 8601에 적합하지 않은 YYYY.MM.DD가 input으로 오면 safari에서 파싱이 안되므로, dayjs에 적합한 포멧 설정을 추가하여 문제없이 변환되도록 합니다.
 */
export const toDateFormat = (date: string | number) => {
  const formats = [
    // ISO 8601 format
    "YYYY-MM-DDTHH:mm:ssZ",
    // ko-KR format
    "YYYY-MM-DD",
    "YYYY.MM.DD",
    "YYYY/MM/DD",
    "YYYYMMDD",
    // en-US format
    "MM-DD-YYYY",
    "MM.DD.YYYY",
    "MM/DD/YYYY",
    "MMDDYYYY",
  ]

  const parsed = dayjs(date, formats, true)
  if (parsed.isValid()) {
    return parsed
  }

  return dayjs(date, undefined, true)
}
