import { BaseResponse } from "@/clients/types"
import { 프로필 } from "@/clients/bolta-apis"
import { instance } from "@/app/(dashboard)/(sidebar)/api/api"

export type GetProfileApi = {
  Params: never
  Response: 프로필.GetProfile.ResponseBody
}

export async function getProfileApi() {
  const {
    data: { body },
  } = await instance<BaseResponse<GetProfileApi["Response"]>>({
    method: "GET",
    url: "/api/v1/profiles",
  })
  return body
}
