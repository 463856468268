import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import { DEFAULT_LOCALE } from "./i18n.constants"
import { LocaleType } from "./i18n.types"
import ko from "./ko"

export function createI18n(locale: LocaleType) {
  const i18n = i18next.createInstance()
  i18n.use(initReactI18next).init({
    debug: false,
    defaultNS: "common",
    fallbackLng: DEFAULT_LOCALE,
    /**
     * @see https://www.i18next.com/translation-function/interpolation
     */
    interpolation: {
      escapeValue: false,
    },
    lng: locale || DEFAULT_LOCALE,
    resources: {
      ko,
    },
  })
  return i18n
}
